import axios from "axios";
import { Formik } from "formik";
import React from "react";
import FormRow from "src/components/form/FormRow";
import StyledForm from "src/components/form/StyledForm";
import H1 from "src/components/H1";
import Header from "src/components/Header";
import ShadowBox from "src/components/ShadowBox";
import { DefaultScreenProps, OrgCreateValues } from "../lib/types";

const StartScreen: React.FC<DefaultScreenProps> = ({ history }) => {
  return (
    <>
      <Header
        title="Välkommen till Framtiden."
        subtitle="Testa TeamPay"
        description="Bara ett klick från vår demomiljö! I demot får du tillgång till vår
          exempelkassa, klicka dig gärna fram och känn hur det känns. Att lära
          sig brukar gå snabbt!"
      />
      <ShadowBox>
        <H1>Vill du bli en game changer i din förening?</H1>
        <p>
          Inför vår stora lansering erbjuder vi utvalda föreningar en 7 dagars
          testperiod.
        </p>
        <p>
          Vill du bli en av dessa föreningar, eller vill du veta mer om TeamPay?
          Fyll i formuläret här nedan så återkommer vi på studs..
        </p>
        <Formik
          initialValues={{
            organizationName: "",
            organizationNumber: "",
            userFirstName: "",
            userLastName: "",
            userMobileNumber: "",
            userEmail: "",
          }}
          validate={(values) => {
            const errors: OrgCreateValues = {};
            if (!values.organizationNumber) {
              errors.organizationNumber = "Obligatorisk";
            }
            if (!values.userEmail) {
              errors.userEmail = "Obligatorisk";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.userEmail)
            ) {
              errors.userEmail = "Ogiltig epostadress";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // check for orgnr
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_URL}/orgcheck/${values.organizationNumber}`,
                {
                  headers: {
                    Authorization: process.env.REACT_APP_BACKEND_AUTH_TOKEN,
                  },
                }
              )
              .then(({ status, data }) => {
                if (status === 200) {
                  setSubmitting(false);
                  history.push("/exists");
                }
              })
              .catch((status) => {
                console.log(status);
                // whoop!
                setSubmitting(false);
                history.push("/start");
              });
            // console.log("status: ", status);
            // console.log(data);

            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <FormRow>
                <label>
                  Förening
                  <input
                    type="text"
                    name="organizationName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organizationName}
                  />
                </label>
              </FormRow>
              <FormRow>
                <label>
                  Organisationsnummer
                  <input
                    type="text"
                    name="organizationNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organizationNumber}
                  />
                </label>
                {errors.organizationNumber &&
                  touched.organizationNumber &&
                  errors.organizationNumber}
              </FormRow>
              <FormRow>
                <label>
                  Ditt förnamn
                  <input
                    type="text"
                    name="userFirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userFirstName}
                  />
                </label>
              </FormRow>
              <FormRow>
                <label>
                  Efternamn
                  <input
                    type="text"
                    name="userLastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userLastName}
                  />
                </label>
              </FormRow>
              <FormRow>
                <label>
                  Mobilnummber
                  <input
                    type="text"
                    name="userMobileNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userMobileNumber}
                  />
                </label>
              </FormRow>
              <FormRow>
                <label>
                  Epost
                  <input
                    type="text"
                    name="userEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userEmail}
                  />
                  {errors.userEmail && touched.userEmail && errors.userEmail}
                </label>
              </FormRow>
              <button type="submit" disabled={isSubmitting}>
                Skicka
              </button>
            </StyledForm>
          )}
        </Formik>
      </ShadowBox>
    </>
  );
};

export default StartScreen;
